<template>
  <section>
    <b-card class="mb-0 h-100">
      <h2>{{ $t('service.helicopter.availability.title') }}</h2>

      <div class="app-calendar overflow-hidden border">
        <div class="row no-gutters">
          <!-- Calendar -->
          <div class="col position-relative">
            <div class="card shadow-none border-0 mb-0 rounded-0">
              <div class="card-body pb-0">
                <full-calendar :options="calendarOptions" class="full-calendar" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <app-sidebar-form
        id="AvailabilitiesForm"
        :edition="!!formMode"
        :edition-mode-title="formMode"
        :entity="availabilitiesHelicopter"
        :title="$t('service.helicopter.availability.title')"
        form-name="availability-form"
        :external-request="onSubmit"
        :show-delete="formMode === 'edit'"
        @delete="modalDeleteAvailability(availabilitiesHelicopter.id)"
        @update:edition="val => {
          if (!val) resetAvailabilitiesHelicopter()
        }"
        no-pristine
      >
        <!-- :submit-btn-text="$t('service.helicopter.availability.add_the_availability')" -->
        <template #fields="{ item }">
          <validation-observer ref="formCalendar" tag="form" @submit.prevent="onSubmit">
            <b-row>
              <b-col cols="12" class="mb-2">
                <app-input id="title" v-model="item.title" label="Title" autofocus required />
              </b-col>

              <b-col cols="6" class="mb-1">
                <validation-provider #default="{ errors }" name="Start date" rules="required" class="validation-required mb-1">
                  <b-form-group label="Start Date" label-for="start-date">
                    <b-form-datepicker
                      v-model="item.start"
                      class="mb-0"
                      :placeholder="$t('common.start_at')"
                      :locale="$i18n.locale"
                      :max="item.end"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="6">
                <b-form-group label="Repeat" label-for="repeat">
                  <b-form-select
                    v-model="dateRepeat"
                    :options="dateRepeatOptions"
                    @input="item.daysOfWeek = dateRepeat === 'everyDays' ? [0, 1, 2, 3, 4, 5, 6] : []"
                  />
                </b-form-group>
              </b-col>

              <b-col v-if="dateRepeat === 'custom'" cols="12">
                <b-form-group class="mb-2">
                  <b-form-checkbox-group
                    v-model="item.daysOfWeek"
                    class="d-flex flex-wrap week"
                    button-variant="outline-primary"
                    :options="daysOfWeekOptions"
                    buttons
                    group-id="radios-btn-default"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <div class="d-flex align-items-center w-100">
                  <b-form-radio v-model="endConfig" class="mr-1" value="never" group-id="some-radios" @change="item.end = null">
                    {{ $t('service.calendar.occurrence.never') }}
                  </b-form-radio>

                  <b-form-radio v-model="endConfig" value="end" group-id="end" />
                  <b-form-datepicker
                    v-model="item.end"
                    :placeholder="$t('common.end_at')"
                    :locale="$i18n.locale"
                    :min="item.start"
                    @input="endConfig = 'end'"
                  />
                </div>
              </b-col>

              <b-col cols="12" sm="6" md="4" class="d-flex align-items-center mt-1 mt-md-0">
                <b-form-radio v-model="endConfig" value="occurrence" group-id="occurrence" class="mr-1 mr-sm-4 mr-md-2">
                  {{ $t('service.calendar.occurrence.after') }}
                </b-form-radio>

                <app-input
                  id="weeksOccurrence"
                  v-model.number="weeksOccurrence"
                  :style-group="{ width: '100px' }"
                  rule="positive"
                  type="number"
                  @input="
                    val => {
                      if (!val || val < 0) weeksOccurrence = 0
                      endConfig = 'occurrence'
                      item.end = $moment(item.start).add(weeksOccurrence, 'weeks').format('YYYY-MM-DD')
                    }
                  "
                />
                <span class="ml-1">{{ $tc('service.calendar.occurrence.title', weeksOccurrence) }}</span>
              </b-col>

              <!-- All Day -->
              <b-col cols="12" class="d-flex align-items-center justify-content-end justify-content-sm-start mt-1 pr-0">
                <b-form-checkbox v-model="dateConfig.allDay" group-id="check-button" switch inline @change="switchAllDay(item.hourAvailabilities, $event)">
                  {{ $t('service.calendar.all_day') }}
                </b-form-checkbox>
              </b-col>
            </b-row>

            <b-form v-if="!dateConfig.allDay" ref="availabilitiesForm" class="repeater-form mt-4" @submit.prevent="addAvailability(item.hourAvailabilities)">
              <b-card v-for="(hourAvailability, index) in item.hourAvailabilities" :key="index" class="border">
                <div class="d-flex align-items-center">
                  <div class="w-100">
                    <b-form-timepicker
                      ref="startTime"
                      v-model="hourAvailability.startHour"
                      :state="hourAvailability.startHour < hourAvailability.endHour"
                      :placeholder="$t('common.start_hour')"
                    />
                  </div>
                  <div class="px-2">-</div>
                  <div class="w-100">
                    <b-form-timepicker ref="endTime" v-model="hourAvailability.endHour" :state="hourAvailability.startHour < hourAvailability.endHour" :placeholder="$t('common.end_hour')" />
                  </div>
                  <b-button v-if="!dateConfig.allDay && item.hourAvailabilities.length > 1" v-ripple.400 variant="flat-danger" class="btn-icon ml-25" @click="modalDeleteItem(item.hourAvailabilities, index)">
                    <feather-icon v-b-tooltip.hover.bottom.v-danger size="21" icon="Trash2Icon" class="text-danger" :title="$t('action.delete')" />
                  </b-button>
                </div>
              </b-card>

              <div class="d-flex justify-content-end">
                <app-btn-add class="mb-4" type="submit">{{ $t('service.calendar.add_new_hour') }}</app-btn-add>
              </div>
            </b-form>
          </validation-observer>
        </template>
      </app-sidebar-form>

      <footer-form-service previous next @click:next="$emit('next-tab')" @click:previous="$emit('previous-tab')" />
    </b-card>
  </section>
</template>

<script>
import { defineComponent, inject, reactive, ref, watch } from '@vue/composition-api'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import momentPlugin from '@fullcalendar/moment'

import AppSidebarForm from '@/components/AppSidebarForm.vue'
import AppBtnAdd from '@/components/AppBtnAdd.vue'
import FooterFormService from '../component/FooterFormService.vue'

/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */

export default defineComponent({
  name: 'HelicopterAvailability',

  components: {
    AppSidebarForm,
    AppBtnAdd,
    FooterFormService,
    FullCalendar,
  },

  setup(_props, ctx) {
    const { $router, $i18n, $moment, $bvModal, _cloneDeep } = ctx.root

    const product = inject('product')
    const formMode = ref(null)
    const displayedDates = ref({})
    const getDay = day => (day ? $moment(day).format('YYYY-MM-DD') : null)

    const setCalendarEvents = availabilitiesHours => {
      calendarOptions.events = availabilitiesHours.map(availabilityHour => {
        const allDay = availabilityHour.startHour === availabilityHour.endHour
        const recur = availabilityHour.daysOfWeek.length < 7

        return {
          allDay,
          groupId: availabilityHour.id,
          title: availabilityHour.title,
          daysOfWeek: recur ? availabilityHour.daysOfWeek : null,
          startTime: !allDay ? availabilityHour.startHour.slice(0, 5) : null, // 08:00 format || null
          endTime: !allDay ? availabilityHour.endHour.slice(0, 5) : null,
          color: '#DCC181',
          textColor: 'white',
          start: $moment(availabilityHour.start).format('YYYY-MM-DD'),
          end: availabilityHour.end ? $moment(availabilityHour.end).add(1, 'days').format('YYYY-MM-DD') : null,
          startRecur: (!allDay || (allDay && !availabilityHour.end))
            ? $moment(availabilityHour.start).format('YYYY-MM-DD') : null,
          endRecur: !allDay ? $moment(availabilityHour.end).add(1, 'days').format('YYYY-MM-DD') : null,
        }
      })
    }

    watch(() => product.value.availabilitiesHours, availabilitiesHours => {
      setCalendarEvents(availabilitiesHours)
    }, { deep: true })

    const fetchHelicopterAvailabilities = () => {
      // Reset calendar's events to prevent to display events of a month into an other month
      calendarOptions.events = []
      product.value.fetchHelicopterAvailabilities({ start: displayedDates.value.start, end: displayedDates.value.end }, ctx.root.$route.params.service_id, ctx.root.$route.query.vid)
    }

    const calendarOptionsEdit = reactive({
      plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin, momentPlugin],
      initialView: ctx.root.$route.query.mode,
      initialDate: $moment(ctx.root.$route.query.start).endOf('isoWeek').format('YYYY-MM-DD'),
      headerToolbar: {
        start: 'sidebarToggle, prev, next, title',
        center: 'addAvailabilitiesBtn',
        end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth,today',
      },
      views: { dayGridMonth: { displayEventEnd: true } },
      customButtons: {
        addAvailabilitiesBtn: {
          text: $i18n.t('service.helicopter.availability.add'),
          click: () => {
            formMode.value = 'add'
          },
        },
      },
      events: [],
      datesSet: event => {
        if (getDay(event.start) === displayedDates.value.start && displayedDates.value.mode === event.view.type) return

        displayedDates.value = {
          start: getDay(event.start),
          end: getDay(event.end),
          mode: event.view.type,
        }
        $router.replace({ query: { ...ctx.root.$route.query, ...displayedDates.value, end: undefined } })
        fetchHelicopterAvailabilities()
      },
      dateClick: date => {
        availabilitiesHelicopter.value.start = date.date
        formMode.value = 'add'
      },
      eventClick: calEvent => {
        product.value.fetchHelicopterAvailability(calEvent.event._def.groupId).then(data => {
          availabilitiesHelicopter.value = data
          initForm()
          formMode.value = 'edit'
        })
      },
      textEscape: false,
      eventResizableFromStart: true,
      fixedWeekCount: false,
      weekNumberCalculation: 'ISO',
      dragScroll: true,
      navLinks: true,
      eventTimeFormat: { // like '14:30:00'
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h23',
        meridiem: false,
      },
      slotLabelFormat: { // like '14:30:00'
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h23',
        meridiem: false,
      },
      locale: $i18n.locale,
    })
    const calendarOptionsShow = reactive({
      plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin, momentPlugin],
      initialView: ctx.root.$route.query.mode,
      initialDate: $moment(ctx.root.$route.query.start).endOf('isoWeek').format('YYYY-MM-DD'),
      headerToolbar: {
        start: 'sidebarToggle, prev, next, title',
        end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth,today',
      },
      views: { dayGridMonth: { displayEventEnd: true } },
      events: [],
      datesSet: event => {
        if (getDay(event.start) === displayedDates.value.start && displayedDates.value.mode === event.view.type) return

        displayedDates.value = {
          start: getDay(event.start),
          end: getDay(event.end),
          mode: event.view.type,
        }
        $router.replace({ query: { ...ctx.root.$route.query, ...displayedDates.value, end: undefined } })
        fetchHelicopterAvailabilities()
      },
      textEscape: false,
      eventResizableFromStart: true,
      fixedWeekCount: false,
      weekNumberCalculation: 'ISO',
      dragScroll: true,
      navLinks: true,
      eventTimeFormat: { // like '14:30:00'
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h23',
        meridiem: false,
      },
      slotLabelFormat: { // like '14:30:00'
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h23',
        meridiem: false,
      },
      locale: $i18n.locale,
    })
    const calendarOptions = ctx.root.$can('OPERATOR_SERVICE_VIEW') ? calendarOptionsShow : calendarOptionsEdit

    const endConfig = ref('never')
    const weeksOccurrence = ref(0)
    const dateRepeat = ref('everyDays')
    const dateRepeatOptions = [
      { value: 'everyDays', text: $i18n.t('service.calendar.repeat_every_days') },
      { value: 'custom', text: $i18n.t('service.calendar.repeat_custom') },
    ]
    const daysOfWeekOptions = [
      { value: 1, text: $i18n.t('common.monday') },
      { value: 2, text: $i18n.t('common.tuesday') },
      { value: 3, text: $i18n.t('common.wednesday') },
      { value: 4, text: $i18n.t('common.thursday') },
      { value: 5, text: $i18n.t('common.friday') },
      { value: 6, text: $i18n.t('common.saturday') },
      { value: 0, text: $i18n.t('common.sunday') },
    ]

    const dateConfig = ref({
      groupId: Math.floor(Math.random() * 9999),
      startTime: '',
      endTime: '',
      allDay: true,
      color: '#DCC181',
      textColor: 'white',
      extendedProps: {},
    })

    const availabilitiesHelicopter = ref({
      id: null,
      title: null,
      start: null,
      end: null,
      daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
      hourAvailabilities: [
        {
          id: null,
          startHour: '00:00:00',
          endHour: '00:00:00',
        },
      ],
    })
    const availabilitiesHelicopterInit = _cloneDeep(availabilitiesHelicopter.value)

    const resetAvailabilitiesHelicopter = () => {
      formMode.value = null
      availabilitiesHelicopter.value = _cloneDeep(availabilitiesHelicopterInit)
      dateConfig.value.allDay = true
    }

    const onSubmit = async payload => {
      const action = payload.id
        ? 'patchHelicopterAvailability'
        : 'postHelicopterAvailability'

      return product.value[action](payload).then(() => {
        fetchHelicopterAvailabilities()
      })
    }

    const initForm = () => {
      // SET allDay
      dateConfig.value.allDay = availabilitiesHelicopter.value.hourAvailabilities[0].startHour === availabilitiesHelicopter.value.hourAvailabilities[0].endHour

      // SET end date radio button
      endConfig.value = availabilitiesHelicopter.value.end ? 'end' : 'never'

      // SET repeat to custom if specifics days selected
      dateRepeat.value = availabilitiesHelicopter.value.daysOfWeek.length < 7 && availabilitiesHelicopter.value.daysOfWeek.length ? 'custom' : 'everyDays'
    }

    const modalConfig = {
      title: $i18n.t('alert.delete_confirmation.title'),
      size: 'sm',
      okVariant: 'primary',
      okTitle: $i18n.t('common.yes'),
      cancelTitle: $i18n.t('common.no'),
      cancelVariant: 'outline-secondary',
      hideHeaderClose: false,
      centered: true,
    }

    const modalDeleteItem = (hourAvailabilities, index) => {
      $bvModal
        .msgBoxConfirm($i18n.t('alert.delete_confirmation.message'), modalConfig)
        .then(ok => {
          if (ok) {
            hourAvailabilities.splice(index, 1)
          }
        })
    }

    const modalDeleteHours = hourAvailabilities => {
      $bvModal
        .msgBoxConfirm($i18n.t('alert.product.availability.delete_hours'), modalConfig)
        .then(ok => {
          if (ok) {
            hourAvailabilities.splice(1, hourAvailabilities.length)
            hourAvailabilities[0].startHour = '00:00:00'
            hourAvailabilities[0].endHour = '00:00:00'
          } else {
            dateConfig.value.allDay = false
          }
        })
    }

    const modalDeleteAvailability = availabilityId => {
      $bvModal
        .msgBoxConfirm($i18n.t('alert.product.availability.delete'), modalConfig)
        .then(ok => {
          if (ok) {
            product.value.deleteHelicopterAvailability(availabilityId).then(() => {
              resetAvailabilitiesHelicopter()
            })
          }
        })
    }

    const switchAllDay = (hourAvailabilities, isAllDay) => {
      if (isAllDay) {
        if (hourAvailabilities.length > 1) {
          modalDeleteHours(hourAvailabilities)
        } else {
          hourAvailabilities[0].startHour = '00:00:00'
          hourAvailabilities[0].endHour = '00:00:00'
        }
      }
    }

    // ADD price multi hours
    const addAvailability = hourAvailabilities => {
      // Copy of previous availability
      const normalizedHourAvailability = _cloneDeep({
        ...hourAvailabilities[hourAvailabilities.length - 1],
        id: null,
      })
      hourAvailabilities.push(normalizedHourAvailability)
    }

    return {
      product,
      formMode,
      endConfig,
      dateRepeat,
      weeksOccurrence,
      availabilitiesHelicopter,
      availabilitiesHelicopterInit,
      resetAvailabilitiesHelicopter,
      dateConfig,
      dateRepeatOptions,
      daysOfWeekOptions,
      onSubmit,
      modalDeleteItem,
      modalDeleteAvailability,
      calendarOptions,
      addAvailability,
      switchAllDay,
    }
  },
})
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";

[dir=ltr] .week.btn-group-toggle [class*=btn-outline-] {
  border-width: 1px !important;
  border-radius: 0 !important;
}

.fc-daygrid-body.fc-daygrid-body-unbalanced {
  width: 100% !important;
}

.fc-scrollgrid-sync-table {
  width: 100% !important;
}

.fc-col-header {
  width: 100% !important;
}

.fc-addAvailabilitiesBtn-button {
  border-color: #DCC181 !important;
  background-color: #DCC181 !important;
}
</style>
